import React from 'react';

const SameLenderFAQ = () => ([
    {
      title: 'What is remortgaging?',
      description: (
        <>
        Remortgaging is the process of getting a new mortgage deal. Whether it's moving from your existing lender to a new lender or staying with your lender on a new mortgage.<br /><br />
        Remortgaging usually happens when the existing mortgage term comes to an end. However, you can remortgage early to release equity, move to a better interest rate should interest rates fall, or move home. However, if you do remortgage early before your existing deal ends, you are likely to be charged an ERC, early repayment charge by the lender.
        </>
      ),
    },
    {
      title: 'How do I know when it\'s the right time to remortgage?',
      description: (
        <>
          The best time to remortgage is when your current deal is ending.<br/><br/>
          Usually, your current lender will get in touch and let you know you can remortgage. It's a good idea to start looking at other mortgage deals about 6 months before your current deal expires so you aren't moved to your lender's standard variable rate (SVR) which is more expensive.
        </>
      ),
    },
    {
      title: 'What are the benefits of a mortgage product transfer?',
      description: (
        <>
          The main advantages of product transfers are that the process is usually a lot quicker and saves you a lot of time because your existing lender already has a lot of your information.<br/><br/>
          You may also not need to do an affordability check or credit check with a product transfer mortgage which not only helps make things quicker but, maybe something you want to consider if your financial circumstances, property valuation or how much equity you have in the home has changed since your initial mortgage deal.<br /><br />
          Your mortgage lender may also offer you an incentive to stay with them to avoid you leaving them for another mortgage lender.<br /><br />
          There aren't usually any legal fees to pay.
        </>
      ),
    },
    {
      title: 'What are the disadvantages of a mortgage product transfer?',
      description: (
        <>
          The disadvantage of a product transfer mortgage is that you are limited to the deals that are on offer as you aren't getting access to the whole of the market, which you would if you considered other mortgage lenders. This means that it could be likely that you aren't getting the best deal for your circumstance.<br/><br/>
          If you have improved your loan-to-value (LTV), credit score, and income then you aren't going to be benefiting from now being eligible for a better deal.<br /><br />
          If you're looking to save money on your monthly mortgage repayments then a considering remortgage with a new lender may be something to consider.
        </>
      ),
    },
    {
      title: 'How do I decide if remortgaging with a new lender or a product transfer mortgage is right for me?',
      description: (
        <>
          Choosing to remortgage with a new lender or staying with one of your existing lender's mortgage products option is dependent on your financial circumstances and preferences.<br/><br/>
          Typically, if you want to pay less in monthly payments on your mortgage then remortgaging to a new lender may be a good idea as you'll have access to more deals. However, if you're looking for speed and avoiding an affordability check then a product transfer may be more suitable.
        </>
      ),
    },
    {
      title: 'Can I do a product transfer with Sprive?',
      description: (
        <>
          Absolutely! As well as helping you find and remortgage to a new lender, Sprive is able to facilitate product transfer mortgages quickly and easily, without charging any mortgage broker fees.
        </>
      ),
    },
    {
      title: 'Can I do a product transfer with Sprive?',
      description: (
        <>
        When you remortgage to a new lender, there can be fees such as arrangement fees, valuation fees, legal fees, and potential exit fees from your current lender. Be sure to factor in these costs when comparing new deals.<br /><br />
        It's also worth checking with your existing lender if there are any early repayment charges if you're considering moving to a new mortgage deal early.<br /><br />
        Although mortgage product transfers tend to not have any fees associated, it's best to check with a mortgage broker or the legal documents to make sure there aren't any hidden fees.
        </>
      ),
    },
  ]);

export default SameLenderFAQ;
